<template>
  <div>
    <div
      v-if="!isComponent"
      class="mb-1"
    >
      <span
        class="link"
        @click="back"
      > Назад </span>
    </div>
    <div class="container card p-3">
      <h1>{{ title }}</h1>
      <!-- Choose type -->
      <b-row v-if="!openForm">
        <b-col
          md="6"
          lg="6"
        >
          <b-card
            title="Barcha texnikalarga"
            class="mb-3 text-center card-bordered"
          >
            <b-card-text> Barcha turdagi mahsulotlar kirimi uchun </b-card-text>
            <b-button
              class="w-100"
              variant="outline-primary"
              @click="openCreateParty(1)"
            >
              Yaratish
            </b-button>
          </b-card>
        </b-col>
        <b-col
          md="6"
          lg="6"
        >
          <b-card
            title="Mobil qurilmalarga"
            class="text-center card-bordered"
          >
            <b-card-text>
              Mobil telefonlar kirimi uchun (IMEI kiritish imkoniyati mavjud)
            </b-card-text>
            <b-button
              class="w-100"
              variant="outline-primary"
              @click="openCreateParty(2)"
            >
              Yaratish
            </b-button>
          </b-card>
        </b-col>
        <b-col
          md="12"
          lg="12"
        >
          <b-card
            title="Optom texnikalarga"
            class="mb-3 text-center card-bordered"
          >
            <b-card-text>
              Optom(chakana) mahsulotlar kirimi uchun. Mahsulod shtrix kodi
              kiritiladi
            </b-card-text>
            <b-button
              class="w-100"
              variant="outline-primary"
              @click="openCreateParty(3)"
            >
              Yaratish
            </b-button>
          </b-card>
        </b-col>
      </b-row>
      <!-- Form -->
      <b-overlay
        v-if="openForm"
        :show="loading"
      >
        <validation-observer
          ref="form"
          #default="{ invalid }"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="savee"
          >
            <div class="row">
              <div class="col-12 col-md-3">
                <b-form-group
                  :label="$t('Поставщик')"
                  label-for="name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-select
                      id="name"
                      v-model="form.provider_id"
                      :state="errors.length > 0 ? false : null"
                      name="name"
                      value-field="id"
                      text-field="name"
                      :placeholder="$t('Поставщик')"
                      :options="providers.data"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-12 col-md-3">
                <b-form-group
                  :label="$t('Дата')"
                  label-for="date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="date"
                    rules="required"
                  >
                    <x-date-picker
                      id="date"
                      v-model="form.date"
                      :state="errors.length > 0 ? false : null"
                      name="date"
                      :placeholder="$t('Дата')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <!-- <div class="col-12 col-md-3">
                <b-form-group
                  label="Investorning hisobidan"
                  label-for="investor_id"
                >
                  <b-form-checkbox
                    v-model="isInvestor"
                    :value="true"
                    :unchecked-value="false"
                    @input="onInvestSelect"
                  >
                    Investorning hisobidan
                  </b-form-checkbox>
                </b-form-group>
              </div> -->
              <div
                v-if="isInvestor"
                class="col-12 col-md-3"
              >
                <b-form-group
                  :label="$t('Investor')"
                  label-for="investor"
                >
                  <v-select
                    id="tag"
                    v-model="investorObj"
                    placeholder="Investorni tanlang"
                    :options="investors.data"
                    :get-option-label="
                      (option) =>
                        option.lastname +
                        ' ' +
                        option.firstname +
                        ' ' +
                        option.middlename
                    "
                  >
                    <template
                      slot="option"
                      slot-scope="option"
                    >
                      <span>
                        {{
                          option.lastname +
                            " " +
                            option.firstname +
                            " " +
                            option.middlename
                        }}
                      </span>
                    </template>
                  </v-select>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-6 col-md-3">
                <b-form-group
                  :label="$t('Курс валют(so\'m)')"
                  label-for="dollar_rate"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="dollar_rate"
                    rules="required"
                  >
                    <cleave
                      id="dollar_rate"
                      v-model="form.dollar_rate"
                      class="form-control"
                      :options="cleaveOption"
                      placeholder="0"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-6 col-md-3">
                <b-form-group
                  :label="$t('Валюта прихода')"
                  label-for="currency"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="currency"
                    rules="required"
                  >
                    <b-form-select
                      id="currency"
                      v-model="currency_id"
                      :state="errors.length > 0 ? false : null"
                      name="currency"
                      value-field="id"
                      text-field="name"
                      :placeholder="$t('Валюта прихода')"
                      :options="currencies"
                      @change="clearCosts"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <b-form-group
                  :label="$t('Комментарий')"
                  label-for="comment"
                >
                  <b-form-textarea
                    id="comment"
                    v-model="form.comment"
                    class="form-control"
                    placeholder="Комментарий"
                    rows="3"
                  />
                </b-form-group>
              </div>
            </div>
            <!-- Products -->
            <div class="">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>№</th>
                    <th>Номенклатура</th>
                    <th>Количиство</th>
                    <th v-if="party_type == 3">
                      Seriya
                    </th>
                    <th v-if="party_type == 2">
                      IMEI 1
                    </th>
                    <th v-if="party_type == 2">
                      IMEI 2
                    </th>
                    <th v-if="currency_id == 1">
                      Цена(USD)
                    </th>
                    <th v-else>
                      Цена(UZS so'm)
                    </th>
                    <th>Сумма</th>
                    <th v-if="party_type == 3">
                      Seriya raqamlar
                    </th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(product, index) in form.products"
                    :key="index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td width="500">
                      <b-overlay :show="!product_categories.data">
                        <b-form-group label-for="category_id">
                          <validation-provider
                            #default="{ errors }"
                            name="category_id"
                            rules="required"
                          >
                            <v-select
                              id="category_id"
                              ref="vSelect"
                              v-model="product.category_id"
                              :disabled="isShow"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :state="errors.length > 3 ? false : null"
                              :options="product_categories.data"
                              :reduce="(option) => option.id"
                              :selectable="
                                (option) =>
                                  party_type == 2
                                    ? true
                                    : !productCategoryIds.includes(option.id)
                              "
                              label="name_uz"
                            >
                              <span
                                slot="no-options"
                                @click="$refs.vSelect.open = false"
                              >
                                Kechirasiz, tovar topilmadi <br>
                                <!-- <router-link :to="{name:'SettingsProductCategory'}"> -->
                                <span
                                  class="link"
                                  @click="product_create_modal = true"
                                >
                                  <feather-icon icon="PlusIcon" />
                                  Tovar qo'shish
                                </span>
                                <!-- </router-link> -->
                              </span>
                            </v-select>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-overlay>
                    </td>
                    <td>
                      <b-form-group label-for="quantity">
                        <validation-provider
                          #default="{ errors }"
                          name="quantity"
                          rules="required"
                        >
                          <b-form-input
                            id="quantity"
                            v-model="product.quantity"
                            v-mask="'#####'"
                            :disabled="party_type == 2 || product.countDisabled"
                            type="number"
                            :state="errors.length > 3 ? false : null"
                            name="quantity"
                            placeholder=""
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </td>
                    <td
                      v-if="party_type == 2"
                      width="400"
                    >
                      <b-form-group label-for="imei_1">
                        <validation-provider
                          #default="{ errors }"
                          name="imei_1"
                          rules="required"
                        >
                          <b-form-input
                            id="imei_1"
                            v-model="product.imei_1"
                            v-mask="'###############'"
                            :state="errors.length > 0 ? false : null"
                            placeholder="123456789012345"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </td>
                    <td
                      v-if="party_type == 2"
                      width="400"
                    >
                      <b-form-group label-for="imei_2">
                        <b-form-input
                          id="imei_2"
                          v-model="product.imei_2"
                          v-mask="'###############'"
                          placeholder="123456789012345"
                        />
                      </b-form-group>
                    </td>
                    <td
                      v-if="party_type == 3"
                      width="400"
                    >
                      <b-form-checkbox
                        v-if="!product.serial_numbers.length"
                        v-model="product.hasSeria"
                        :value="true"
                        :unchecked-value="false"
                        @input="onHasSeria(product.hasSeria, index)"
                      >
                        Seriyali
                      </b-form-checkbox>
                      <b-form-group label-for="imei_2">
                        <b-form-input
                          id="imei_2"
                          ref="barcodeProductInput"
                          v-model="product.serial_number"
                          placeholder="123456789012345"
                          :disabled="!product.countDisabled"
                          @keyup.enter="
                            getCountByBarcode(product.serial_number, index)
                          "
                        />
                      </b-form-group>
                    </td>
                    <td>
                      <b-form-group label-for="cost_dollar">
                        <validation-provider
                          #default="{ errors }"
                          name="cost_dollar"
                          rules="required"
                        >
                          <cleave
                            v-if="isHWorkerRoute || currency_id == 2"
                            id="cost_dollar"
                            v-model="product.cost"
                            class="form-control"
                            :state="errors.length > 0 ? false : null"
                            :options="cleaveOption"
                            :disabled="isShow"
                            placeholder="0"
                          />
                          <cleave
                            v-else
                            id="cost_dollar"
                            v-model="product.cost_dollar"
                            class="form-control"
                            :state="errors.length > 0 ? false : null"
                            :options="cleaveOption"
                            :disabled="isShow"
                            placeholder="0"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </td>

                    <td width="15%">
                      <span v-if="isHWorkerRoute || currency_id == 2">
                        {{ (product.quantity * product.cost) | formatPrice }}
                        so'm
                      </span>
                      <span v-else>
                        {{
                          (product.quantity * product.cost_dollar) | formatPrice
                        }}
                        $
                      </span>
                      <br>
                      <span v-if="form.dollar_rate && currency_id == 2">
                        {{
                          (product.cost / form.dollar_rate).toFixed(2) *
                            product.quantity
                        }}
                        USD
                      </span>
                      <span v-else-if="form.dollar_rate && product.cost_dollar">
                        {{
                          (product.quantity *
                            form.dollar_rate *
                            product.cost_dollar)
                            | formatPrice
                        }}
                        UZS
                      </span>
                    </td>
                    <td>
                      <el-tag
                        v-for="(tag, tIndex) in product.serial_numbers.slice(
                          0,
                          3
                        )"
                        :key="'k' + tIndex"
                        closable
                        class="mb-1 mr-1"
                        @close="handleCloseBarcode(tag, index)"
                      >
                        {{ tag }}
                      </el-tag>
                      <b-button
                        v-if="product.serial_numbers.length > 3"
                        class="mt-1"
                        variant="outline-primary"
                        @click="showAllSerials(index)"
                      >
                        Barchasini ko'rish
                      </b-button>
                    </td>
                    <td>
                      <feather-icon
                        v-if="form.products.length != 1"
                        color="#FF0000"
                        icon="TrashIcon"
                        @click="deleteProduct(index)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-button
              class="float-left mt-2"
              variant="outline-primary"
              @click="addProduct()"
            >
              <feather-icon icon="PlusIcon" />
              Добавить
            </b-button>
            <div class="d-flex justify-content-end">
              <b-overlay :show="loadingBtn">
                <b-button
                  class="mt-5 float-right"
                  variant="primary"
                  @click="save()"
                >
                  Сохранить
                </b-button>
              </b-overlay>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </div>
    <!-- Rejected Products -->
    <div
      v-if="rejectedProducts && rejectedProducts.length"
      class="container card p-2"
    >
      <h1 class="text-warning">
        Возвращенный товары
      </h1>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>№</th>
            <th>Номенклатура</th>
            <th>Количиство</th>
            <th>Комментарий</th>
            <th>Дата</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(product, index) in rejectedProducts"
            :key="index"
          >
            <td>{{ index + 1 }}</td>
            <td width="400">
              <span v-if="product.product && product.product.category">
                {{ product.product.category.name_uz }}
              </span>
            </td>
            <td>
              {{ product.rejected_quantity }}
            </td>
            <td>
              {{ product.comment }}
            </td>
            <td>
              {{ formatDateYmd(product.created_at) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="reject_modal">
      <b-modal
        v-model="reject_modal"
        size="md"
        centered
        hide-footer
        no-close-on-backdrop
        @close="reject_modal = false"
        @cancel="reject_modal = false"
      >
        <RejectProductReason
          :is-hired="isHWorkerRoute"
          :product="reject_product"
          @submit="reject"
        />
      </b-modal>
    </div>
    <div v-if="product_create_modal">
      <b-modal
        v-model="product_create_modal"
        size="xl"
        centered
        hide-footer
        no-close-on-backdrop
        @close="product_create_modal = false"
        @cancel="product_create_modal = false"
      >
        <ProductCategoryCreate />
      </b-modal>
    </div>
    <!--  -->
    <div v-if="showAllSerialsModal">
      <b-modal
        v-model="showAllSerialsModal"
        size="md"
        centered
        hide-footer
        no-close-on-backdrop
        title="Barcha seriya raqamlar"
        @close="showAllSerialsModal = false"
        @cancel="showAllSerialsModal = false"
      >
        <el-tag
          v-for="tag in form.products[productModalIndex].serial_numbers"
          :key="tag"
          closable
          class="mb-1 mr-1"
          @close="handleCloseBarcode(tag, productModalIndex)"
        >
          {{ tag }}
        </el-tag>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import { clearObject } from '@/utils'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import Print from '@/views/print/print.vue'
import RejectProductReason from '@/components/modals/rejectProductReason.vue'
import ProductCategoryCreate from '@/views/settings/product-category/index.vue'
import VueBarcode from 'vue-barcode'

export default {
  name: 'Create',
  components: {
    barcode: VueBarcode,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    Cleave,
    Print,
    RejectProductReason,
    ProductCategoryCreate,
  },
  props: {
    pPartyId: {
      type: Number,
      default: () => null,
    },
    isComponent: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      cleaveOption: { numeral: true, numeralThousandsGroupStyle: 'thousand' },
      reject_modal: false,
      rejectedProducts: [],
      investorObj: '',
      party_type: 1,
      openForm: false,
      currencies: [
        { id: 1, name: 'USD(dollar)' },
        { id: 2, name: "UZS(so'm)" },
      ],
      currency_id: 1,
      reject_product: {},
      isInvestor: false,
      showAllSerialsModal: false,
      productModalIndex: 0,
      loadingBtn: false,
      form: {
        id: null,
        provider_id: null,
        date: null,
        comment: null,
        dollar_rate: null,
        investor_id: null,
        products: [
          {
            category_id: null,
            quantity: 1,
            cost: null,
            imei_1: null,
            imei_2: null,
            serial_number: null,
            serial_numbers: [],
            hasSeria: false,
            countDisabled: false,
            cost_dollar: null,
          },
        ],
      },
      filterProduct: {
        level: 3,
        per_page: 9999,
        belongs_to_hired_worker: null,
        name_uz: '',
      },
      product_barcode: '',
      product_name: '',
      show_print_barcode: false,
      product_create_modal: false,
      title: '',
      visible: false,
      loading: false,
      required,
    }
  },
  computed: {
    ...mapGetters({
      cities: 'city/GET_ITEMS',
      providers: 'providers/GET_ITEMS',
      partiesInfo: 'parties/GET_ITEM',
      investors: 'investment/GET_ITEMS',
      product_categories: 'productCategory/GET_ITEMS',
      unit_of_measures: 'resource/UNIT_OF_MEASURES',
    }),
    isHWorker() {
      return !!this.partiesInfo.hired_worker_user_id
    },
    itemId() {
      if (this.pPartyId) {
        return this.pPartyId
      }
      return this.$route.params.id
    },
    isShow() {
      return !!this.$route.params.id
    },
    isHWorkerRoute() {
      return !!(this.$route.name == 'warehouseParty-show-hworker')
    },
    storageUrl() {
      return `${process.env.VUE_APP_BASE_URL}storage/`
    },
    productCategoryIds() {
      const productIds = []
      this.form.products.forEach(el => {
        if (el.category_id) {
          productIds.push(el.category_id)
        }
      })
      return productIds
    },
  },
  watch: {
    $route(newVal) {
      if (newVal) {
        clearObject(this.form)
        this.form.products = [
          {
            category_id: null,
            quantity: 1,
            cost: null,
            cost_dollar: null,
          },
        ]
      }
    },
    investorObj(newVal) {
      this.form.investor_id = newVal.id
    },
  },
  mounted() {
    this.title = this.$t('Поступление товаров')
    if (this.isHiredWorker) {
      this.getProviders({ hired_workers: 1, per_page: 9999 })
      this.filterProduct.belongs_to_hired_worker = 1
      this.fetchProducts()
    } else {
      this.getProviders({ hired_workers: 0, per_page: 9999 })
      this.filterProduct.belongs_to_hired_worker = 0
      this.fetchProducts()
    }
    this.getItemsAction({ per_page: 999 })
    this.fetchUnitMeasures()
  },
  methods: {
    savee() {
      console.log('saving')
    },
    async save() {
      const valid = await this.validationForm()
      if (valid) {
        this.addCostDollar()
        this.addCost()
        this.loadingBtn = true
        this.method(this.form)
          .then(res => {
            showToast(
              'success',
              this.$t('Успешно сохранено'),
              'CheckCircleIcon',
            )
            this.$router.push({
              name: 'warehouseParty-show',
              params: { id: res.data.id },
            })
            this.visible = false
          })
          .catch(err => {
            if (err.status === 422) {
              this.$refs.form.setErrors(err.data.errors)
            }
            showToast('danger', this.$t('Ошибка'), 'XIcon')
          })
          .finally(() => {
            this.loadingBtn = false
          })
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },
    openRejectModal(product) {
      this.reject_modal = true
      this.reject_product = product
    },
    openCreateParty(type = 1) {
      this.party_type = type
      this.openForm = true
    },
    addCost() {
      if (this.form.dollar_rate && this.currency_id == 1) {
        this.form.products.map(product => {
          product.cost = this.form.dollar_rate * product.cost_dollar
          return product
        })
      }
    },
    addCostDollar() {
      if (this.form.dollar_rate && this.currency_id == 2) {
        this.form.products.forEach(product => {
          product.cost_dollar = (product.cost / this.form.dollar_rate).toFixed(
            2,
          )
        })
      }
    },
    showPrint(barcode, name) {
      this.product_barcode = barcode
      this.product_name = name
      this.show_print_barcode = true
    },
    getCountByBarcode(val, index) {
      // const haSeria = this.form.products[index].serial_numbers.some(el => el == val)
      if (val) {
        this.form.products[index].serial_numbers.push(String(val))
        this.form.products[index].serial_number = null
        this.form.products[index].quantity = this.form.products[index].serial_numbers.length
      }
      // else if (haSeria) {
      //   showToast('warning', 'Ushbu seriyani kiritdingiz')
      // }
    },
    method(data) {
      if (this.party_type == 2) {
        return this.storePhoneItem(data)
      }
      if (this.party_type == 3) {
        return this.storeSerialParties(data)
      }

      return this.storeItem(data)
    },
    reject(data) {
      if (this.isHWorkerRoute) {
        this.rejectProductHiredWorker(data).then(res => {
          if (res.success) {
            showToast('success', this.$t('Успешно сохранено'))
            this.reject_modal = false
            this.showAction()
          }
        })
      } else {
        this.rejectProduct(data).then(res => {
          if (res.success) {
            showToast('success', this.$t('Успешно сохранено'))
            this.reject_modal = false
            this.showAction()
          }
        })
      }
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    fetchProducts() {
      this.getCategories(this.filterProduct)
    },
    fetchUnitMeasures() {
      this.unitOfMeasures()
    },
    addProduct() {
      this.form.products.push({
        category_id: null,
        quantity: 1,
        cost_dollar: null,
        imei_1: null,
        imei_2: null,
        serial_number: null,
        serial_numbers: [],
        hasSeria: false,
        countDisabled: false,
        cost: null,
      })
    },
    clearCosts() {
      this.form.products.forEach(el => {
        el.cost = null
        el.cost_dollar = null
      })
    },
    onInvestSelect() {
      if (!this.isInvestor) {
        this.form.investor_id = null
      }
    },
    showAction() {
      let showData = null
      this.loading = true
      if (this.isHWorkerRoute) {
        showData = {
          id: this.itemId,
          relations:
            'provider|products.category|rejectedProducts.createdUser|rejectedProducts.product.category',
        }
      } else {
        showData = {
          id: this.itemId,
          relations:
            'provider|visibleProducts.category|rejectedProducts.createdUser|rejectedProducts.product.category',
        }
      }
      this.showItem(showData)
        .then(res => {
          this.rejectedProducts = res.data.rejected_products
          this.form.id = this.partiesInfo.id
          this.form.provider_id = this.partiesInfo.provider_id
          this.form.date = this.partiesInfo.date
          this.form.comment = this.partiesInfo.comment
          this.form.dollar_rate = this.partiesInfo.dollar_rate
          const prods = this.isHWorkerRoute ? 'products' : 'visible_products'
          this.form.products = this.partiesInfo[prods].map(product => ({
            category_id: product.category_id,
            quantity: product.quantity,
            cost_dollar: product.cost_dollar,
            cost: product.cost,
            id: product.id,
            barcode: product.barcode,
            serial_numbers: product.serial_numbers,
            imei_1: product.imei_1,
            imei_2: product.imei_2,
            name: product.category ? product.category.name_uz : '',
          }))
        })
        .finally(() => {
          this.loading = false
        })
    },
    deleteProduct(index) {
      this.form.products.splice(index, 1)
    },
    makeNakladnoy() {
      this.makeNakladnoyPdf(this.$route.params.id).then(res => {
        window.open(
          `${`${this.storageUrl}/nakladnoys/${res.data}`}.pdf`,
          '_blank',
        )
      })
    },
    handleCloseBarcode(tag, index) {
      this.form.products[index].serial_numbers.splice(
        this.form.products[index].serial_numbers.indexOf(tag),
        1,
      )
      this.form.products[index].quantity = this.form.products[index].serial_numbers.length
    },
    showAllSerials(index) {
      this.showAllSerialsModal = true
      this.productModalIndex = index
    },
    onHasSeria(val, index) {
      if (val) {
        this.form.products[index].quantity = 0
        this.form.products[index].countDisabled = true
      } else {
        this.form.products[index].quantity = 1
        this.form.products[index].countDisabled = false
      }
    },
    ...mapActions({
      getProviders: 'providers/index',
      getCategories: 'productCategory/index',
      storeItem: 'parties/store',
      storePhoneItem: 'parties/storePhone',
      storeSerialParties: 'parties/storeSerialParties',
      showItem: 'parties/show',
      unitOfMeasures: 'resource/unitOfMeasures',
      makeNakladnoyPdf: 'resource/makeNakladnoyPdf',
      updateItem: 'parties/update',
      getItemsAction: 'investment/index',
      rejectProduct: 'warehouse/rejectProduct',
      rejectProductHiredWorker: 'warehouse/rejectProductHiredWorker',
    }),
  },
}
</script>
